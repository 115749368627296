import React from 'react'
import BeachPleaseImg from '../../assets/projects/Beach_Cleanup_Image_Dummy.jpg'

export const BeachPlease = () => {
  return (
        <div className="grid grid-cols-1 md:grid-cols-2 my-32">
            <div className="our-values h-fit mx-6">
                <h1 className='text-4xl h-fit font-bold text-primary-50'> 
                    Beach Please
                </h1>
                
                <p className="mt-8 text-lg text-justify md:w-4/5 h-fit break-words mb-6">
                "Beach Please" is a community-driven beach cleanup event dedicated to preserving our coastal environments. Participants join forces to remove litter and debris from shorelines, promoting environmental stewardship and ocean conservation. This initiative not only enhances the beauty of our beaches but also raises awareness about the importance of protecting marine ecosystems. "Beach Please" embodies the spirit of volunteerism and collective action, inspiring individuals to take responsibility for their natural surroundings and contribute to a cleaner, healthier planet.
                </p>
                
                <img src={BeachPleaseImg} alt="Thrust Areas" className='w-full h-full md:hidden mx-auto object-cover rounded-xl' />     
            </div>
              
            <img src={BeachPleaseImg} alt="Thrust Areas" className='w-full h-full md:block hidden mx-auto object-cover rounded-xl' />
        </div>
  )
}
