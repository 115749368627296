import React from 'react'
import REDImg from '../../assets/projects/RED_Image.jpg'

export const RED = () => {
  return (
        <div className="grid grid-cols-1 md:grid-cols-2 my-32">
            <div className="our-values h-fit mx-6">
                <h1 className='text-4xl h-fit font-bold text-primary-50'> 
                    R.E.D
                </h1>
                
                <p className="mt-8 text-lg text-justify md:w-4/5 h-fit break-words mb-6">
                RED is an electrifying cultural event featuring spirited competitions among the four zones within District 3142. This event celebrates diversity and creativity through various cultural competitions, highlighting the rich traditions and talents of each zone. RED fosters a sense of unity and pride as Rotaractors showcase their cultural heritage and artistic expressions. It's a vibrant platform where participants come together to promote cultural exchange, mutual understanding, and friendship, reinforcing the bonds within the Rotaract community.
                </p>
                
                <img src={REDImg} alt="Thrust Areas" className='w-full h-full md:hidden mx-auto object-cover rounded-xl' /> 

            </div>
              
            <img src={REDImg} alt="Thrust Areas" className='w-full h-full md:block hidden mx-auto object-cover rounded-xl' />
        </div>
  )
}
