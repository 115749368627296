import React from 'react'
import ExploreImg from '../../assets/projects/Explore_Image.JPG'

export const Explore = () => {
  return (
        <div className="grid grid-cols-1 md:grid-cols-2 my-32">
            <div className="our-values h-fit mx-6">
                <h1 className='text-4xl h-fit font-bold text-primary-50'> 
                    Explore 3142
                </h1>
                
                <p className="mt-8 text-lg text-justify md:w-4/5 h-fit break-words mb-6">
                "Explore 3142" is an exciting initiative where Rotaractors from various clubs come together to visit and experience the vibrant city of Mumbai during Ganesh Chaturthi. Hosted by the 3142 district, this event offers a unique opportunity for participants to immerse themselves in the rich cultural festivities, explore the city’s iconic landmarks, and engage in meaningful interactions with the local community. From visiting bustling markets and historic sites to participating in the lively celebrations, "Explore 3142" is all about fostering friendship, cultural exchange, and shared experiences among Rotaractors from around the world. It’s a journey of discovery, learning, and celebration, creating lasting memories and strengthening the bonds within the global Rotaract family.
                </p>

                <img src={ExploreImg} alt="Thrust Areas" className='w-full h-full md:hidden mx-auto object-cover rounded-xl' />     
            </div>
              
            <img src={ExploreImg} alt="Thrust Areas" className='w-full h-full md:block hidden mx-auto object-cover rounded-xl' />
        </div>
  )
}
