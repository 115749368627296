import React from 'react'
import RISEImg from '../../assets/projects/RISE_Image.jpg'


export const RISE = () => {
  return (
        <div className="grid grid-cols-1 md:grid-cols-2 my-32">
            <div className="our-values h-fit mx-6">
                <h1 className='text-4xl h-fit font-bold text-primary-50'> 
                    R.I.S.E
                </h1>
                
                <p className="mt-8 text-lg text-justify md:w-4/5 h-fit break-words mb-6">
                RISE is an exhilarating sports event where four zones within District 3142 compete passionately for the coveted RISE Trophy. This event not only showcases athletic prowess but also fosters camaraderie and team spirit among participants. It's a platform where Rotaractors from different zones come together to celebrate sportsmanship, healthy competition, and the joy of community engagement. RISE represents unity and determination as participants strive to achieve their best and uphold the values of sportsmanship and fellowship within the Rotaract community.
                </p>
                
                <img src={RISEImg} alt="Thrust Areas" className='w-full h-full md:hidden mx-auto object-cover rounded-xl' />     
            </div>
              
            <img src={RISEImg} alt="Thrust Areas" className='w-full h-full md:block hidden mx-auto object-cover rounded-xl' />
        </div>
  )
}
