import React from 'react'
import RotaractLogo from '../assets/logos/Rotaract Logo White.svg'
import { Link, NavLink } from 'react-router-dom'

export const Footer = () => {
  return (
    <div>
          <footer class="bg-primary-50 text-white py-12 md:pl-8">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mx-8">
                <div class="col-span-1 md:col-span-1 lg:col-span-1 lg:col-start-1">
                    <div className="md:p-5 lg:p-5 mb-4">
                        <img src={RotaractLogo} alt="Rotaract Logo" class="h-12 mb-4"/>
                        <p class="text-sm">
                            Rotaract District 3142 is a youth organization that focuses on providing a platform to help the next generation discover themselves.
                        </p>
                    </div>
                </div>
                <div class="col-span-1 md:col-span-1 lg:col-span-1 flex md:justify-center lg:justify-center md:items-center lg:items-center mr-4 md:mr-0 lg:mr-0">
                    <ul class="list-none text-left">
                        <li class="mb-2"><NavLink to="/" class="hover:underline">Home</NavLink></li>
                        <li class="mb-2"><NavLink to="/about" class="hover:underline">About Us</NavLink></li>
                        <li class="mb-2"><NavLink to="/events" class="hover:underline">Events</NavLink></li>
                    </ul>
                </div>
                <div class="col-span-1 md:col-span-1 lg:col-span-1 flex md:items-center lg:items-center mb-6 md:mb-0 md:mg-0">
                    <ul class="list-none text-left lg:pb-2">
                        <li class="mb-2"><NavLink to="/blogs" class="hover:underline">Blogs</NavLink></li>
                        <li class="mb-2"><NavLink to="/contact" class="hover:underline">Contact</NavLink></li>
                        <li><Link to="https://pranali.rotaract3142.org/" class="hover:underline">Pranali 3142</Link></li>
                    </ul>
                </div>          
                <div class="col-span-1 md:col-span-1 lg:col-span-1 flex md:items-center lg:items-center">
                    <ul class="list-none text-left">
                        <li class="mb-2"><a href="mailto:contact@rotaract3142.org" class="hover:underline">contact@rotaract3142.org</a></li>
                    </ul>
                </div>
            </div>
            
            <div className="flex justify-between items-center mt-28">
                <div className="flex space-x-1 mb-4 md:ml-14 ml-6">
                    <a href="https://www.instagram.com/rotaract3142/?hl=en" className="text-white hover:text-gray-400 py-3 px-5 rounded-lg" style={{backgroundColor: 'rgba(255, 255, 255, 0.2)' }}><i className="fab fa-instagram"></i></a>
                    <a href="https://x.com/rotaract_3142" className="text-white hover:text-gray-400 py-3 px-5 rounded-lg" style={{backgroundColor: 'rgba(255, 255, 255, 0.2)' }}><i className="fab fa-twitter"></i></a>
                    <a href="https://www.linkedin.com/company/rotaract-district-3142/mycompany/" className="text-white hover:text-gray-400 py-3 px-5 rounded-lg" style={{backgroundColor: 'rgba(255, 255, 255, 0.2)' }}><i className="fab fa-linkedin-in"></i></a>
                </div>
                <p className="hidden md:block text-sm mr-24">&copy; 2024 - Developed by TEAM IT 3142</p>
            </div>
         </footer>
    </div>
  )
}
