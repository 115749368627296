import React from 'react'
import DOHImg from '../../assets/projects/DOH_Image.jpg'

export const DropOfHope = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="our-values h-fit mx-6">
            <h1 className='text-4xl h-fit font-bold text-primary-50'> 
                Drop of Hope
            </h1>
            
            <p className="mt-8 text-lg text-justify md:w-4/5 h-fit break-words mb-6">
            The "Drop of Hope" blood donation event is a cornerstone of community support, bringing together individuals committed to making a difference through their contributions. By participating, donors not only provide life-saving blood but also demonstrate solidarity and compassion for their fellow community members. This event serves as a reminder of the power of collective action in improving health outcomes and fostering a stronger, more resilient community. Each donation at "Drop of Hope" represents a gesture of generosity and kindness that leaves a lasting impact on those in need of critical medical care.
            </p>
            
            <img src={DOHImg} alt="Thrust Areas" className='w-full h-full md:hidden mx-auto object-cover rounded-xl' />
        </div>
            <img src={DOHImg} alt="Thrust Areas" className='w-full h-full md:block hidden mx-auto object-cover rounded-xl' />
    </div>
  )
}
