import React from 'react'
import InfoLogo from '../assets/logos/Info Icon.png'

export const SingleCard = ({ title, description }) => {
  return (
    <div className='container'>
        <div className="max-w-sm min-h-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 shadow-lg">
            <div className="p-5">
                <a href="1">
                    <img className="rounded-t-lg py-2" src={InfoLogo} alt="" />
                </a>
                <a href="1">
                    <h5 className="mb-2 text-lg py-2 font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
                </a>
                <p className="mb-3 text-sm font-normal text-gray-700 dark:text-gray-400">{description}</p>
  {/*<a href="1" className="inline-flex items-center px-2 py-2 text-sm font-medium text-center text-primary-50 hover:text-white rounded-lg hover:bg-primary-50 dark:bg-primary-50 dark:hover:bg-white dark:hover:text-primary-50">
                    Learn more
                    <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </a>*/}
            </div>
        </div>
    </div>
  )
}
