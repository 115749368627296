import React from 'react'
import { Chinmay, Devasish, Himanshu, Jitesh, Nisha, Omkar, Pranod, Ramiya, Rohan, Rohit, Ruhika, Sushmita, Viren } from '../assets/Team Images'


export const Team = () => {
  return (
    <section className="bg-white dark:bg-gray-900 ">
    <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6 ">
        <div className="mx-auto mb-8 max-w-screen-sm lg:mb-16">
            <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 dark:text-white">Meet Our Remarkable Core Team</h2>
            <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Meet the team member who's been setting the bar incredibly high. Their talent and drive are exactly what makes us unstoppable</p>
        </div> 
        <div className="md:grid lg:grid gap-8 lg:gap-16 flex 
       flex-wrap items-center justify-center md:grid-cols-3 lg:grid-cols-4">
            <div className="text-center text-gray-500 dark:text-gray-400">
                <img className="object-cover object-top w-36 h-36 max-w-full mx-auto mb-4 rounded-full" src={Nisha} alt="Nisha Raju"/>
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white ">
                    <a href="#">Rtr. Rtn. Nisha Raju</a>
                </h3>
                <p>District Rotaract Representative</p>
                
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
                <img className=" object-cover w-36 h-36 max-w-full mx-auto mb-4 rounded-full" src={ Sushmita } alt="Sushmita Valecha Bhatia"/>
                <h3 className="mb-1 text-2xl w-max font-bold tracking-tight text-gray-900 dark:text-white">
                    <a href="#">Rtr. Rtn. Sushmita Valecha</a>
                </h3>
                <p>Immediate Past DRR</p>
                
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
                <img className="object-cover w-36 h-36 max-w-full mx-auto mb-4 rounded-full" src={Rohit} alt="Rohit Deshpande"/>
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    <a href="#">Rtr. Rohit Deshpande</a>
                </h3>
                <p>District Secretary</p>
                
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
                <img className="object-cover w-36 h-36 max-w-full mx-auto mb-4 rounded-full" src={Jitesh} alt="Jitesh Bhatia"/>
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    <a href="#">Rtr. Jitesh Bhatia</a>
                </h3>
                <p>Finance Chair & DRR Elect</p>
                
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
                <img className="object-cover w-36 h-36 max-w-full mx-auto mb-4 rounded-full" src={Pranod} alt="Pranod Kalsekar"/>
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    <a href="#">Rtr. Pranod Kalsekar</a>
                </h3>
                <p>S.A.A. & Zonal Chair</p>
                
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
                <img className="object-cover object-top w-36 h-36 max-w-full mx-auto mb-4 rounded-full" src={Ramiya} alt="Ramiya Pillai"/>
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    <a href="#">Rtr. Ramiya Pillai</a>
                </h3>
                <p>Chair: Avenues 1</p>
                
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
                <img className="object-cover object-top w-36 h-36 max-w-full mx-auto mb-4 rounded-full" src={Himanshu} alt="Himanshu Dapurkar"/>
                <h3 className="mb-1 text-2xl w-max font-bold tracking-tight text-gray-900 dark:text-white">
                    <a href="#">Rtr. Himanshu Dapurkar</a>
                </h3>
                <p>Chair: Avenues 2</p>
                
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
                <img className="w-36 h-36 object-cover object-top mx-auto mb-4 rounded-full" src={Viren} alt="Viren Lassi"/>
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    <a href="#">Rtr. Viren Lassi</a>
                </h3>
                <p>Chair: Avenues 3 & Thrust Areas</p>
                
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
                <img className="object-cover w-36 h-36 max-w-full mx-auto mb-4 rounded-full" src={Ruhika} alt="Ruhika Kalsekar"/>
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    <a href="#">Rtr. Ruhika Kalsekar</a>
                </h3>
                <p>Chair: T.R.S</p>
                
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
                <img className="object-cover w-36 h-36 max-w-full mx-auto mb-4 rounded-full" src={Omkar} alt="Omkar Joshi"/>
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    <a href="#">Rtr. Omkar Joshi</a>
                </h3>
                <p>Chair: Digital Communications</p>
                
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
                <img className="object-cover object-top w-36 h-36 max-w-full mx-auto mb-4 rounded-full" src={Rohan} alt="Rohan Solanki"/>
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    <a href="#">Rtr. Rohan Solanki</a>
                </h3>
                <p>Chair: Corporate Communications</p>
                
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400">
                <img className="object-cover w-36 h-36 max-w-full mx-auto mb-4 rounded-full" src={Devasish} alt="Devashish Mande"/>
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    <a href="#">Rtr. Devashish Mande</a>
                </h3>
                <p>Chair: Admin</p>
                
            </div>
            <div className="text-center text-gray-500 dark:text-gray-400 col-span-4 justify-self-center">
                <img className="object-cover w-36 h-36 max-w-full mx-auto mb-4 rounded-full" src={Chinmay} alt="Chinmay Pradhan"/>
                <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    <a href="#">Rtr. Chinmay Pradhan</a>
                </h3>
                <p>Chair: Information Technology</p>
                
            </div>
        </div>  
    </div>
    </section>
  )
}
