import React from 'react'
import BackgroundImage from '../assets/backgrounds/header.png';
import Scrolldown from '../assets/logos/scroll down.png'
import {
    BlogSection,
    CardSection,
    Footer,
    Header,
    Hero,
    Initiatives,
    InsightsStrip,
    MottoStrip,
    Partners,
    ThrustAreasSection
} from '../components';
import {useDynamicTitle} from '../hooks';


export const LandingPage = ({title}) => {

    useDynamicTitle(title);

    return (
        <div className='bg-cover bg-no-repeat bg-center max-h-screen'
             style={{backgroundImage: `url(${BackgroundImage})`}}>
            <Header/>
            <Hero/>
            <div className="scroll-down mt-36 mx-36">
                <img src={Scrolldown} alt="scroll down"/>
            </div>
            <div className="card-container mx-8">
                <CardSection/>
            </div>
            <MottoStrip/>
            <div className="card-container mx-8">
                <ThrustAreasSection/>
            </div>
            <div className="card-container mx-8">
                <Initiatives/>
            </div>
            <InsightsStrip/>
            <div className="card-container mx-8">
                <BlogSection/>
            </div>
            <Partners/>
            <Footer/>
        </div>
    )
}
