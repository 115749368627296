import React from 'react'

export const InsightsStrip = () => {
    return (
        <section className="bg-primary-50 w-full overflow-hidden py-12 mt-16 mb-6">
            <div className="container mx-auto md:px-4">
                <div className="flex flex-col md:flex-row justify-between items-center text-white text-center space-y-6 md:space-y-0 md:space-x-6">
                    <div className="flex-1">
                        <p className="text-3xl font-bold">2k+</p>
                        <p className="uppercase tracking-widest">Members Registered</p>
                    </div>
                    <div className="flex-1">
                        <p className="text-3xl font-bold">80+</p>
                        <p className="uppercase tracking-widest">No. of Clubs</p>
                    </div>
                    <div className="flex-1">
                        <p className="text-3xl font-bold">10k+</p>
                        <p className="uppercase tracking-widest">Happy Faces</p>
                    </div>
                    <div className="flex-1">
                        <p className="text-3xl font-bold">20k+</p>
                        <p className="uppercase tracking-widest">Lives Changed</p>
                    </div>
                </div>
            </div>
        </section>
    );
};
