import React from 'react'
import ThrustAreasImg from '../assets/projects/RED_Image.jpg'
import StackLogo from '../assets/logos/Stack.png'
import CPULogo from '../assets/logos/Cpu.png'

export const ThrustAreasSection = () => {
    return (
        <div className='mx-auto md:p-10 mt-20 md:mt-0'>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-16"> {/* add gap-4 */}
                <div className="thrust-areas"> {/*remove md:mx-6*/}
                    <h1 className='text-4xl font-bold'>
                        <span className='text-primary-50'>Thrust Areas</span><br/>Driving 3142
                    </h1>
                    <p className="mt-8 text-lg break-words mb-6"> {/* remove md:w-4/5 */}
                        Join Rotaract 3142 to gain invaluable skills and experiences. Unlock your potential through
                        continuous learning and development.
                    </p>
                    <div className="grid justify-center">
                        <div
                            className="max-w-xl mt-10 bg-yellow-50 p-6 md-mx-6 rounded-lg shadow-md my-16"> {/* remove max-w-md */}
                            <div className="flex items-start mb-6">
                                <div className="mr-4">
                                    <img src={StackLogo} alt="Environment Protection Icon"
                                         className="md:w-20 md:h-10 w-24 h-7 md:mt-0 lg:mt-0 mt-4"/>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold text-gray-900">Environment Protection</h3>
                                    <p className="mt-1 text-gray-700">Rotaract District 3142 promote environmental
                                        protection through community initiatives and awareness campaigns that encourage
                                        sustainable practices.</p>
                                </div>
                            </div>
                            <hr className="border-t border-gray-300"/>
                            <div className="flex items-start my-6">
                                <div className="mr-4">
                                    <img src={StackLogo} alt="Mental Health Awareness Icon"
                                         className="md:w-20 md:h-10 w-24 h-7 md:mt-0 lg:mt-0 mt-4"/>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold text-gray-900">Mental Health Awareness</h3>
                                    <p className="mt-1 text-gray-700">Rotaract District emphasizes mental health by
                                        raising awareness, providing support programs, and creating safe spaces for open
                                        discussions.</p>
                                </div>
                            </div>
                            <hr className="border-t border-gray-300"/>
                            <div className="flex items-start mt-6">
                                <div className="mr-4">
                                    <img src={CPULogo} alt="Rotaract Branding Icon"
                                         className="md:w-20 md:h-10 w-24 h-7 md:mt-0 lg:mt-0 mt-4"/>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold text-gray-900">Rotaract Branding</h3>
                                    <p className="mt-1 text-gray-700">Rotaract branding creates a strong identity
                                        reflecting service, leadership, and community engagement through consistent
                                        visuals and messaging that resonate with youth.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={ThrustAreasImg} alt="Thrust Areas" className='w-full h-full object-cover rounded-xl'/>
            </div>
        </div>
    )
}
