import React from 'react';
import DropOfHope from '../assets/projects/DOH_Image.jpg';
import Daptar from '../assets/projects/Daptar_Image.jpg';
import Explore from '../assets/projects/Explore_Image.JPG';
import {NavLink} from 'react-router-dom';

export const Initiatives = () => {
    return (
        <section className="bg-white md:p-10 relative">
            <div className="py-8">
                <div className="flex flex-col md:flex-row md:justify-between sm:text-justify md:items-center my-8">
                    <div className="md:max-w-lg lg:max-w-lg text-left">
                        <h2 className="text-4xl md:text-5xl font-bold md:mb-0 lg:mb-0">
                            <span className="text-primary-50">Initiatives</span> that defines
                            us who we are
                        </h2>
                    </div>
                    {/* <div className="hidden md:block space-x-2"> remove md:mr-20
                        <button className="text-lg text-primary-50 px-4 py-2 rounded"
                                style={{backgroundColor: 'rgba(0, 0, 255, 0.1)'}}>
                            <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <button className="text-lg text-primary-50 px-4 py-2 rounded"
                                style={{backgroundColor: 'rgba(0, 0, 255, 0.1)'}}>
                            <i className="fa-solid fa-arrow-right"></i>
                        </button>
                    </div> */}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-20">
                    <figure className="relative mx-auto md:mx-0"
                            style={{maxHeight: '450px'}}> {/* remove max-w-sm*/}
                        <a href="1">
                            <img className="rounded-lg w-full h-full" src={DropOfHope} alt="Drop Of Hope"/>
                        </a>
                        <div
                            className="absolute inset-0 bg-gradient-to-b from-transparent via-black/10 to-black/70 rounded-lg"></div>
                        <figcaption className="absolute px-4 text-xl text-white font-extrabold bottom-6">
                            <p>Drop Of Hope</p>
                        </figcaption>
                    </figure>


                    <figure className="relative mx-auto md:mx-0" style={{maxHeight: '450px'}}>
                        <a href="1">
                            <img className="rounded-lg h-full" src={Daptar} alt="Daptar"/>
                        </a>
                        <div
                            className="absolute inset-0 bg-gradient-to-b from-transparent via-black/10 to-black/70 rounded-lg"></div>
                        <figcaption className="absolute px-4 text-xl text-white font-extrabold bottom-6">
                            <p>Daptar</p>
                        </figcaption>
                    </figure>

                    <figure className="relative mx-auto md:mx-0" style={{maxHeight: '450px'}}>
                        <a href="1">
                            <img className="rounded-lg w-full h-full" src={Explore} alt="Explore"/>
                        </a>
                        <div
                            className="absolute inset-0 bg-gradient-to-b from-transparent via-black/10 to-black/70 rounded-lg"></div>
                        <figcaption className="absolute px-4 text-xl text-white font-extrabold bottom-6">
                            <p>Explore</p>
                        </figcaption>
                    </figure>
                </div>
                <div className="mt-16 flex justify-center">
                    <NavLink to="/events"
                             className="inline-block border border-primary-50 text-primary-50 px-6 py-2 rounded-md font-semibold hover:bg-primary-50 hover:text-white transition-colors">
                        View All →
                    </NavLink>
                </div>
            </div>
        </section>
    );
};
