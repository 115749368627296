import React from 'react'
import { SingleCard } from './SingleCard';

const cardData = [
    { title: 'Leadership Quality', description: 'We promote leadership by empowering young individuals to take initiative, make decisions, and lead with integrity to create lasting impact.' },
    { title: 'Personality Development', description: 'We at 3142 promote personality development by fostering self-growth, enhancing communication, and building confidence through diverse opportunities.' },
    { title: 'Career Development', description: 'Rotaract District 3142 promotes career development through networking, mentorship, and skill-building opportunities.' },
    { title: 'Social Networking', description: 'We promotes social networking by connecting members with like-minded individuals and fostering meaningful relationships.' },
    { title: 'International Exposure', description: 'Rotaract District 3142 promotes international exposure by connecting members globally, broadening their perspectives and understanding of diverse cultures.' },
    { title: 'Sports Development', description: 'We at promote sports by encouraging teamwork and active participation, fostering camaraderie and physical well-being among members.' },
];
  
export const CardSection = () => {
  return (
    <div className='md:p-10 mb-24'>
        <div className='grid gap-10 sm:grid-cols-2 lg:grid-cols-4'>
            <div className='sm:col-span-2 lg:col-span-2 mx-6'>
                <h1 className='md:text-5xl text-4xl font-bold'>
                    Your Path to
                </h1>
                <span className='text-primary-50 text-4xl md:text-5xl font-bold'>Learning & Growth</span>
                <p className="mt-4 text-sm w-4/5 break-words">
                    Join Rotaract 3142 to gain invaluable skills and experiences. Unlock your potential through continuous learning and development.
                </p>
            </div>
            {cardData.map((card, index) => (
                <SingleCard key={index} title={card.title} description={card.description}/>
            ))}
        </div>
    </div>
  )
}
