import { useEffect } from 'react'

export const useDynamicTitle = (title, defaultTitle='Rotaract District 3142') => {
    useEffect(()=> {
        document.title = title;
        return () => {
            document.title = defaultTitle;
        }
    },[title,defaultTitle])
}
