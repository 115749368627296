import React from 'react'
import ValuesImg from '../assets/projects/Explore_Image.JPG'
import StackLogo from '../assets/logos/Stack.png'
import CPULogo from '../assets/logos/Cpu.png'

export const Values = () => {
  return (
    <div className='md:mx-auto md:p-10 mt-20 md:mt-0'>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
            <div className="our-values h-fit mx-6">
                <h1 className='text-4xl h-fit font-bold'>
                    Our <span className='text-primary-50'>Values & </span><br /><span className='text-primary-50'>Principles</span>
                </h1>

                <p className="mt-8 text-lg text-justify h-fit break-words mb-6">
                Rotaract 3142, guided by its core values and principles, stands as a beacon of service, leadership, and fellowship. Founded on the belief in making a meaningful impact, we at rotaract 3142 dedicate ourselves to community service, embodying integrity and respect in all endeavours. Embracing diversity and fostering inclusivity, clubs within our district cultivate an environment where members can develop personally and
                professionally. Through innovative projects and collaborations, we at rotaract 3142 not only addresses societal challenges but also nurtures future leaders committed to positive change and sustainable development.
                </p>

                <img src={ValuesImg} alt="Thrust Areas" className='w-full h-full md:hidden mx-auto object-cover rounded-xl' />
            </div>

            <img src={ValuesImg} alt="Thrust Areas" className='w-full h-auto lg:max-h-96 md:block hidden object-cover rounded-xl' />
        </div>
    </div>
  )
}
