import React from 'react'
import { DropOfHope } from './events/DropOfHope'
import { Daptar } from './events/Daptar'
import { Explore } from './events/Explore'
import { RISE } from './events/RISE'
import { RED } from './events/RED'
import { BeachPlease } from './events/BeachPlease'

export const EventsList = () => {
  return (
    <div className='md:mx-auto md:p-10 mt-20 md:mt-0'>     
        <DropOfHope/>
        <Daptar/>
        <Explore/>
        <RISE/>
        <RED/>
        <BeachPlease/>
    </div>
  )
}
