import React, { useRef, useState } from 'react';
import SpotifyLogo from '../assets/logos/Spotify-Code.png';
import RotaractTheme from '../assets/audio/Ekatra 3142 (RID 3142 Official Anthem) - Rotaract District 3142.mp3'; // Import your audio file

export const BlogsHero = () => {
  const audioRef = useRef(null); 
  const [isPlaying, setIsPlaying] = useState(false); 

  const handleLogoClick = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause(); 
      } else {
        audioRef.current.play(); 
      }
      setIsPlaying(!isPlaying); 
    }
  };

  return (
    <main className="text-center py-16">
      <h1 className="text-4xl md:text-6xl font-bold">
        <span className="text-primary-50">Blogs</span> of 3142
      </h1>
      <p className="text-lg text-pretty text-gray-700 mt-4">
        Rotaract District 3142 is a youth organization that focuses on providing a <br />platform to help the next generation discover themselves.
      </p>
      <div className="mt-10 mx-auto w-full max-w-xs">
        <img
          src={SpotifyLogo}
          alt="Spotify-Code-Logo"
          className="mx-auto cursor-pointer"
          onClick={handleLogoClick} 
        />
      </div>
      {/* Audio element */}
      <audio ref={audioRef} src={RotaractTheme} preload="auto" />
    </main>
  );
};
