import React from 'react'
import BackgroundImage from '../assets/backgrounds/header.svg';
import { ContactForm, ContactUsHero, Footer, Header } from '../components'
import { useDynamicTitle } from '../hooks';

export const ContactPage = ({title}) => {

  useDynamicTitle(title);

  return (
    <div className='bg-cover bg-no-repeat bg-center max-h-screen' style={{ backgroundImage: `url(${BackgroundImage})`}}>
      <Header />
      <ContactUsHero />
      <ContactForm />
      <Footer/>
    </div>
  )
}
