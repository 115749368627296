import React, { useRef, useState } from 'react';
import MiddleLogo from '../assets/logos/3142 Play Button Logo.png';
import RotaractTheme from '../assets/audio/Ekatra 3142 (RID 3142 Official Anthem) - Rotaract District 3142.mp3'; 

export const Hero = () => {
  const audioRef = useRef(null); // Create a reference for the audio element
  const [isPlaying, setIsPlaying] = useState(false); // State to track whether the audio is playing

  const handleLogoClick = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause(); // Pause the audio
      } else {
        audioRef.current.play(); // Play the audio
      }
      setIsPlaying(!isPlaying); // Toggle the play/pause state
    }
  };

  return (
    <main className="text-center pt-16">
      <h1 className="text-4xl md:text-6xl font-bold">
        We are Rotaract District <span className="text-primary-50">3142</span>
      </h1>
      <p className="text-lg text-pretty text-gray-700 mt-4">
        Rotaract District 3142 is a youth organization that focuses on providing a <br />platform to help the next generation discover themselves.
      </p>
      <div className="mt-10 mx-auto w-full max-w-xs">
        <img
          src={MiddleLogo}
          alt="3142-Logo"
          className="mx-auto cursor-pointer"
          onClick={handleLogoClick} 
        />
      </div>
      {/* Audio element */}
      <audio ref={audioRef} src={RotaractTheme} preload="auto" />
    </main>
  );
};
