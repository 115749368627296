import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import Carousel CSS
import { Carousel } from 'react-responsive-carousel';
import Lava from '../assets/logos/Company logos/Lava.png';
import Lenovo from '../assets/logos/Company logos/Lenovo.png';
import Slack from '../assets/logos/Company logos/Slack.png';
import Youtube from '../assets/logos/Company logos/Youtube.png';
import Amazon from '../assets/logos/Company logos/Amazon.png';
import Google from '../assets/logos/Company logos/Google.png';

export const Partners = () => {
  return (
    <div className='my-24'>
      <h1 className='text-4xl flex justify-center font-bold mt-24 mb-14'>Our Partners</h1>

      {/* Desktop View */}
      <div className='hidden md:grid lg:grid grid-cols-6 justify-items-center gap-6'>
        <img src={Lava} alt="Lava" className='mx-4' />
        <img src={Lenovo} alt="Lenovo" className='mx-4' />
        <img src={Slack} alt="Slack" className='mx-4' />
        <img src={Youtube} alt="Youtube" className='mx-4' />
        <img src={Amazon} alt="Amazon" className='mx-4' />
        <img src={Google} alt="Google" className='mx-4' />
      </div>

      {/* Mobile Carousel View */}
      <div className='md:hidden'>
        <Carousel
          showArrows={false}
          showThumbs={false} // Hide thumbnails
          showStatus={false} // Hide slide count status
          showIndicators={false} // Hide control dots
          autoPlay={true}
          infiniteLoop={true}
          interval={3000} // Auto change every 3 seconds
          swipeable={true}
          emulateTouch={true}
          centerMode={true} // Center each slide
          centerSlidePercentage={80} // Adjust width of the slide (80% for centering effect)
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                className="absolute top-1/2 left-2 z-10 bg-transparent border border-gray-300 p-2 rounded-full hover:bg-gray-100 transition-colors"
                style={{ transform: 'translateY(-50%)' }}
              >
                <span className="text-2xl text-gray-700">❮</span>
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                className="absolute top-1/2 right-2 z-10 bg-transparent border border-gray-300 p-2 rounded-full hover:bg-gray-100 transition-colors"
                style={{ transform: 'translateY(-50%)' }}
              >
                <span className="text-2xl text-gray-700">❯</span>
              </button>
            )
          }
        >
          
          <div className='flex justify-center'>
            <img src={Lava} alt="Lava" className='mx-4 w-1/3' />
          </div>
          
          <div className='flex justify-center'>
            <img src={Lenovo} alt="Lenovo" className='mx-4 w-1/3' />
          </div>
          
          <div className='flex justify-center'>
            <img src={Slack} alt="Slack" className='mx-4 w-1/3' />
          </div>
          
          <div className='flex justify-center'>
            <img src={Youtube} alt="Youtube" className='mx-4 w-1/3' />
          </div>
          
          <div className='flex justify-center'>
            <img src={Amazon} alt="Amazon" className='mx-4 w-1/3' />
          </div>
          
          <div className='flex justify-center'>
            <img src={Google} alt="Google" className='mx-4 w-1/3' />
          </div>
          
          
        </Carousel>
      </div>

      
    </div>
  );
};
