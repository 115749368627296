import React from 'react';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { AppRoutes } from './components/AppRoutes';

// import './App.css';


export default function App() {
  return (
    <div>
      
      <AppRoutes/>
      
    </div>
  )
}


