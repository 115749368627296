import React from 'react'
import BackgroundImage from '../assets/backgrounds/header.svg';
import { EventsHero, EventsList, Footer, Header, MottoStrip } from '../components'
import { useDynamicTitle } from '../hooks';

export const EventsPage = ({title}) => {

  useDynamicTitle(title);

  return (
    <div className='bg-cover bg-no-repeat bg-center max-h-screen' style={{ backgroundImage: `url(${BackgroundImage})`}}>
      <Header/>
      <EventsHero/>
      <MottoStrip/>
      <EventsList/>
      <Footer/>
    </div>
  )
}
