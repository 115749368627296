import React from 'react'
import '../index.css'

export const MottoStrip = () => {
  return (
    <>
      
      <div className='motto-strip bg-primary-50 my-6 lg:block md:hidden hide-on-mobile'>
          <p className="text-white text-center text-pretty" style={{ fontSize: '1.35rem', fontFamily: 'serif'}}>
              {/* S FELLOWSHIP THROUGH SERVICE - THE ROTARACT MOTTO IS FELLOWSHIP THROUGH SERVICE - THE ROTARACT MOTTO I */}
              THE ROTARACT MOTTO IS FELLOWSHIP THROUGH SERVICE - THE ROTARACT MOTTO IS FELLOWSHIP THROUGH SERVICE 
              <br />
              THE ROTARACT MOTTO IS FELLOWSHIP THROUGH SERVICE - THE ROTARACT MOTTO IS FELLOWSHIP THROUGH SERVICE
              {/* <br />
              OUGH SERVICE - THE ROTARACT MOTTO IS FELLOWSHIP THROUGH SERVICE - THE ROTARACT MOTTO IS FELLOWSHIP THR */}
          </p>
      </div>

      <div className='motto-strip bg-primary-50 my-6 lg:hidden hide-on-mobile'>
          <p className="text-white text-center text-pretty" style={{ fontSize: '1.35rem', fontFamily: 'serif'}}>
              {/* S FELLOWSHIP THROUGH SERVICE - THE ROTARACT MOTTO IS FELLOWSHIP THROUGH SERVICE - THE ROTARACT MOTTO I */}
              THE ROTARACT MOTTO IS FELLOWSHIP THROUGH SERVICE
              <br />
              THE ROTARACT MOTTO IS FELLOWSHIP THROUGH SERVICE
              {/* <br />
              OUGH SERVICE - THE ROTARACT MOTTO IS FELLOWSHIP THROUGH SERVICE - THE ROTARACT MOTTO IS FELLOWSHIP THR */}
          </p>
      </div>
      
      <div className='motto-strip bg-primary-50'>
          <p className="flex md:hidden lg:hidden flex-row text-white text-center p-1" style={{ fontSize: '1.35rem', fontFamily: 'serif'}}>
              THE ROTARACT MOTTO IS FELLOWSHIP THROUGH SERVICE
              <br />
               
          </p>
          
      </div>
    </>
  )
}
