import React from 'react'
import { Link } from 'react-router-dom';

export const SingleBlog = ({ source, likes, comments, description, authorImage, authorName, isComingSoon, link }) => {
  return (
    <Link to={link}>
    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 hover:shadow-xl">
      <img className="rounded-t-lg w-full h-64 md:h-64 lg:h-64 object-cover" src={source} alt="" />
      <div className="p-5">
        {!isComingSoon ? (
          <>
            <div className="flex space-x-6 items-center">
              <a href="1">
                <h5 className="mb-2 text-base font-normal tracking-tight dark:text-white space-x-2">
                  <i className="fa-regular fa-thumbs-up"></i>
                  <span className='my-4 font-bold'>{likes}</span> Likes
                </h5>
              </a>
              <a href="1">
                <h5 className="mb-2 text-base font-normal tracking-tight dark:text-white space-x-2">
                  <i className="fa-regular fa-comment-dots"></i>
                  <span className='my-4 font-bold'>{comments}</span> Comments
                </h5>
              </a>
            </div>
            <p className="mb-3 text-base font-bold text-left text-gray-700 dark:text-gray-400">{description}</p>
            <div className="flex items-center">
              <img src={authorImage} alt="blog writer 1" className='rounded-full max-w-14 max-h-14 align-middle' />
              <div className="flex-col mb-4">
                <span className='text-sm mx-4 text-center'>{authorName}</span>
                <p className='text-sm font-normal text-left mx-4'>5 min read</p>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center">
            {/* <h3 className="text-2xl font-bold text-gray-800 dark:text-white">Coming Soon</h3> */}
            <p className="text-gray-500 dark:text-gray-400 text-3xl p-11">Stay tuned for our latest content!</p>
          </div>
        )}
      </div>
    </div>
    </Link>
  );
}
