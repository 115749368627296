import React from 'react'

export const ContactUsHero = () => {
  return (
    <main className="text-center py-16">
      <h1 className="text-4xl md:text-6xl font-bold">
        <span className="text-primary-50">Contact</span> of 3142
      </h1>
      <p className="text-lg text-pretty text-gray-700 mt-4">
        Rotaract District 3142 is a youth organization that focuses on providing a <br />platform to help the next generation discover themselves.
      </p>
      
    </main>
  )
}
