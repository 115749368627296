import React from 'react'
import BackgroundImage from '../assets/backgrounds/header.svg';
import { AboutUsHero, Footer, Header, InsightsStrip, MottoStrip, Partners, Team, ThrustAreasSection, Values, YearTheme } from '../components'
import { useDynamicTitle } from '../hooks';

export const AboutUsPage = ({title}) => {

  useDynamicTitle(title);

  return (
    <div className='bg-cover bg-no-repeat bg-center max-h-screen' style={{ backgroundImage: `url(${BackgroundImage})`}}>
      <Header />
      <AboutUsHero />
      <MottoStrip />
      <Values />
      <YearTheme />
      <div className="card-container mx-8">
        <ThrustAreasSection/>
      </div>
      <InsightsStrip />
      <Team/>
      <Partners/>
      <Footer/>
    </div>
    
  )
}
