import React from 'react'
import BackgroundImage from '../assets/backgrounds/header.svg';
import { useNavigate } from 'react-router-dom';
import { useDynamicTitle } from '../hooks';


export const PageNotFound = ({title}) => {

    const navigate = useNavigate();
    useDynamicTitle(title);

    const handleBackToHome = () => {
        navigate("/");
    }

  return (
    <section className='bg-cover bg-no-repeat bg-center max-h-screen' style={{ backgroundImage: `url(${BackgroundImage})`}}>
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
            <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-50">404</h1>
            
            <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Something's missing.</p>
            <br />
            <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
            <button onClick={handleBackToHome} class="inline-flex text-white bg-primary-50 hover:bg-pink-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Back to Homepage</button>
        </div>   
    </div>
</section>
  )
}
