import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AboutUsPage, BlogsPage, ContactPage, EventsPage, LandingPage, PageNotFound } from '../pages'

export const AppRoutes = () => {
  return (
    <div>
        <Routes>
            <Route path='/' element={<LandingPage title="Home | Rotaract District 3142"/>}/>
            <Route path='/about' element={<AboutUsPage title="About | Rotaract District 3142"/>} />
            <Route path='/events' element={<EventsPage title="Events | Rotaract District 3142"/>} />
            <Route path='/blogs' element={<BlogsPage title="Blogs | Rotaract District 3142"/>} />
            <Route path='/contact' element={<ContactPage title="Contact | Rotaract District 3142"/>} />
            <Route path='*' element={<PageNotFound title="404 | Page Not Found"/>} />
        </Routes>
    </div>
  )
}
