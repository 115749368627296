import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import DistrictLogo from '../assets/logos/Rotaract Logo Cranberry.svg';
import { NavLink } from 'react-router-dom';

export const Header = () => {

    const [isOpen, setIsOpen] = useState(false);
    // const navigate = useNavigate(); 

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLoginClick = () => {
        window.location.href = "https://pranali.rotaract3142.org/"; 
    };

    const activeClasses = "block py-2 px-3 text-primary-50 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-50 md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700";
    const inactiveClasses = "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-900 md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700";

    return (
        <main>
            <div className='header'>
                <div className="dark:bg-gray-900 w-full z-20 top-0 start-0">
                    <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                        {/* Mobile Header */}
                        <div className="md:hidden lg:hidden section grid grid-cols-3 items-center justify-items-center">
                            <NavLink to="/" className="flex items-center justify-center space-x-3 rtl:space-x-reverse ml-2">
                                <img src={DistrictLogo} className="h-8 md:ml-60" alt="District Logo" />
                            </NavLink>
                            <div className="md:hidden lg:hidden ml-3 md:order-2 md:space-x-0 rtl:space-x-reverse flex justify-center items-center">
                                <button type="button" className="text-white bg-primary-50 hover:bg-slate-900 hover:text-white font-medium text-sm px-2 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700" onClick={handleLoginClick}>
                                    Login
                                </button>
                            </div>
                            <div className="md:hidden lg:hidden ml-20 md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse flex justify-center items-center">
                                <button
                                    onClick={toggleMenu}
                                    data-collapse-toggle="navbar-sticky"
                                    type="button"
                                    className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700"
                                    aria-controls="navbar-sticky"
                                    aria-expanded={isOpen}
                                >
                                    <span className="sr-only">Open main menu</span>
                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        {isOpen && (
                            <div className="md:hidden lg:hidden block w-full md:order-1">
                                <ul className="flex flex-col p-4 mt-4 font-medium border rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                                    <li>
                                        <NavLink to="/" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-50 md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-50 md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/events" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-50 md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Events</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/blogs" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-50 md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Blogs</NavLink>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <NavLink to="/contact" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-50 md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Contact</NavLink>*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        )}

                        {/* Desktop Header */}
                        <NavLink to="/" className="hidden md:block lg:block items-center space-x-3 rtl:space-x-reverse ml-2">
                            <img src={DistrictLogo} className="h-8 lg:ml-60" alt="District Logo" />
                        </NavLink>
                        <div className="hidden md:block md:ml-16 lg:block mr-60 md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                            <button type="button" className="text-white bg-primary-50 hover:bg-slate-900 hover:text-white font-medium text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700" onClick={handleLoginClick}>Login</button>
                        </div>

                        <div className="items-center justify-between w-80 hidden md:flex md:order-1" id="navbar-sticky">
                            <ul className="flex flex-col mr-44 p-1 md:p-0 mt-4 font-medium border rounded-lg md:space-x-8 md:flex-row md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                                <li>
                                    <NavLink to="/" className={({ isActive }) => isActive ? activeClasses : inactiveClasses} aria-current="page">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/about" className={({ isActive }) => isActive ? activeClasses : inactiveClasses} aria-current="page">About</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/events" className={({ isActive }) => isActive ? activeClasses : inactiveClasses} aria-current="page">Events</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/blogs" className={({ isActive }) => isActive ? activeClasses : inactiveClasses} aria-current="page">Blogs</NavLink>
                                </li>
                                {/*<li>*/}
                                {/*    <NavLink to="/contact" className={({ isActive }) => isActive ? activeClasses : inactiveClasses} aria-current="page">Contact</NavLink>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
