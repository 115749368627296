import React from 'react'
import DaptarImg from '../../assets/projects/Daptar_Image.jpg'

export const Daptar = () => {
  return (
        <div className="grid grid-cols-1 md:grid-cols-2 my-32">
            <div className="our-values h-fit mx-6">
                <h1 className='text-4xl h-fit font-bold text-primary-50'> 
                    Project Daptar
                </h1>
                
                <p className="mt-8 text-lg text-justify md:w-4/5 h-fit break-words mb-6">
                Project Daptar is dedicated to educating underprivileged children, aiming to empower them through knowledge and opportunity. It strives to bridge educational gaps by providing quality teaching and mentorship in a supportive environment. Through this initiative, children are equipped with essential skills and confidence to pursue brighter futures. Project Daptar embodies a commitment to equality and access to education, ensuring every child has the chance to learn, grow, and succeed.
                </p>

                <img src={DaptarImg} alt="Thrust Areas" className='w-full h-full md:hidden mx-auto object-cover rounded-xl' />     
            </div>
              
            <img src={DaptarImg} alt="Thrust Areas" className='w-full h-full md:block hidden mx-auto object-cover rounded-xl' />
        </div>
  )
}
