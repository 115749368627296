import React from 'react'
import BackgroundImage from '../assets/backgrounds/header.svg';
import { Blogs, BlogsHero, Footer, Header, MottoStrip } from '../components';
import { useDynamicTitle } from '../hooks';

export const BlogsPage = ({title}) => {

  useDynamicTitle(title);

  return (
    <div className='bg-cover bg-no-repeat bg-center max-h-screen' style={{ backgroundImage: `url(${BackgroundImage})`}}>
      <Header />
      <BlogsHero />
      <MottoStrip />
      <Blogs />
      <Footer/>
    </div>
  )
}
