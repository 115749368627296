import React from 'react'
import BlogImage1Source from '../assets/backgrounds/blogs-image-1.png'
import BlogImage2Source from '../assets/backgrounds/blogs-image-2.png'
import BlogImage3Source from '../assets/backgrounds/Blog3.jpg'
import Writer1 from '../assets/logos/profilePlaceHolder.jpg'
import Writer3 from '../assets/logos/blogwriter3.png'
import {SingleBlog} from './SingleBlog'

const blogData = [
    {
        blogImage: BlogImage1Source,
        likes: '2728',
        comments: '274',
        description: 'Daptar Initiative: more than just a project, it’s a transformative...',
        authorImage: Writer1,
        authorName: 'Rtr. Sunayna Anand',
        link: 'https://www.linkedin.com/pulse/daptar-initiative-more-than-just-project-its-transformative-ywgvf/?trackingId=aix0mSJCSue7j5c3D0gHNA%3D%3D'
    },
    {
        blogImage: BlogImage2Source,
        likes: '2729',
        comments: '273',
        description: 'Olympic Values Beyond the Games: A Pathway to Education, Empowerment...',
        authorImage: Writer1,
        authorName: 'Rtr. Neeraj Narkhede',
        link: 'https://www.linkedin.com/pulse/olympic-values-beyond-games-pathway-education-empowerment-7wkuf/?trackingId=v8OY4Dgx5lGA1zWdTF5oEg%3D%3D'
    },
    {
        blogImage: BlogImage3Source,
        likes: '2730',
        comments: '275',
        description: '',
        authorImage: Writer3,
        authorName: 'Savannah Nguyen',
        isComingSoon: true
    },
];

export const BlogSection = () => {
    return (
        <div>
            <div className='md:p-10 text-5xl font-bold text-center mt-16'>
                Latest Blog
                <div className="text-base text-center font-normal my-6 flex justify-center">
                    <p>
                        These blogs have been written by our fellow Rotaractors, showcasing their unique experiences and
                        insights on various projects and initiatives.
                    </p>
                </div>
                <div
                    className=" my-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center">
                    {blogData.map((card, index) => (
                        <SingleBlog
                            key={index}
                            source={card.blogImage}
                            likes={card.likes}
                            comments={card.comments}
                            description={card.description}
                            authorImage={card.authorImage}
                            authorName={card.authorName}
                            isComingSoon={card.isComingSoon}
                            link={card.link}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
